import {
  types,
  flow,
  detach
} from 'mobx-state-tree';
import statisticsService from '../../services/StatisticsService';
import {
  TotalStatisticsType,
  JobStatisticsType,
  UserStatisticsType,
  GridTotalStatisticsType,
  GridStatisticsType
} from './StatisticsTypes';

export const Statistics = types
  .model('Statistics', {
    totalStatistics: types.optional(types.array(TotalStatisticsType), []),
    jobStatistics: types.optional(types.array(JobStatisticsType), []),
    userStatistics: types.maybeNull(UserStatisticsType),
    gridTotalStatistics: types.optional(types.array(GridTotalStatisticsType), []),
    gridStatistics: types.optional(types.array(GridStatisticsType), []),
  })
  .actions(self => ({
    async getTotalStatistics(params) {
      const res = await statisticsService.getTotal(params);
      return self.getTotalStatisticsSuccess(res);
    },
    getTotalStatisticsSuccess(res) {
      if (res) {
        detach(self.totalStatistics);
        self.totalStatistics = res.data;
      } else {
        self.totalStatistics = [];
      }
    }
  }))
  .actions(self => ({
    async getJobStatistics(params) {
      const res = await statisticsService.getJob(params);
      return self.getJobStatisticsSuccess(res);
    },
    getJobStatisticsSuccess(res) {
      if (res) {
        detach(self.jobStatistics);
        self.jobStatistics = res.data;
      } else {
        self.jobStatistics = [];
      }
    }
  }))
  .actions(self => ({
    async getUserStatistics(params) {
      const res = await statisticsService.getUser(params);
      return self.getUserStatisticsSuccess(res);
    },
    getUserStatisticsSuccess(res) {
      if (res) {
        self.userStatistics = res.data;
      } else {
        self.userStatistics = null;
      }
    }
  }))
  .actions(self => ({
    async getGridStatistics(params) {
      const res = await statisticsService.getGrid(params);
      return self.getGridStatisticsSuccess(res);
    },
    getGridStatisticsSuccess(res) {
      if (res) {
        detach(self.gridStatistics);
        self.gridStatistics = res.data;
      } else {
        self.gridStatistics = [];
      }
    }
  }))
  .actions(self => ({
    async getGridTotalStatistics(params) {
      const res = await statisticsService.getGridTotal(params);
      return self.getGridTotalStatisticsSuccess(res);
    },
    getGridTotalStatisticsSuccess(res) {
      if (res) {
        detach(self.gridTotalStatistics);
        self.gridTotalStatistics = res.data.reverse(); 
      } else {
        self.gridTotalStatistics = [];
      }
    }
  }))
  ;
