import { types } from 'mobx-state-tree';
import { TargetCurrencyType } from '../targetCurrency/TargetCurrencyTypes';

export const WaveType = types
  .model('WaveType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    exchangeId: types.maybeNull(types.integer),
    keyId: types.maybeNull(types.integer),
    targetCurrencyId: types.maybeNull(types.integer),
    type: types.maybeNull(types.integer),
    profitType: types.maybeNull(types.integer),

    initialAmount: types.maybeNull(types.string),
    initialCoins: types.maybeNull(types.string),
    accumulateAmount: types.maybeNull(types.string),
    accumulateCoins: types.maybeNull(types.string),
    gap: types.maybeNull(types.integer),
    ignorePercentage: types.maybeNull(types.string),
    stopLoss: types.maybeNull(types.string),
    amountPercentage:types.maybeNull(types.string),

    trigger1Type: types.maybeNull(types.integer),
    trigger1Direction: types.maybeNull(types.integer),
    trigger1Id: types.maybeNull(types.integer),

    trigger2Type: types.maybeNull(types.integer),
    trigger2Direction: types.maybeNull(types.integer),
    trigger2Id: types.maybeNull(types.integer),
    
    trigger3Type: types.maybeNull(types.integer),
    trigger3Direction: types.maybeNull(types.integer),
    trigger3Id: types.maybeNull(types.integer),
    
    trigger4Type: types.maybeNull(types.integer),
    trigger4Direction: types.maybeNull(types.integer),
    trigger4Id: types.maybeNull(types.integer),
    
    status: types.maybeNull(types.integer),
    dryRun: types.maybeNull(types.integer),
    createdTime: types.maybeNull(types.string),
    currency: TargetCurrencyType,
    
  });

  export const WaveTriggerType = types
  .model('WaveTriggerType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    waveId: types.maybeNull(types.integer),
    serialNbr: types.maybeNull(types.integer),
    triggerNbr: types.maybeNull(types.integer),
    direction: types.maybeNull(types.integer),
    symbol: types.maybeNull(types.string),
    planAmount: types.maybeNull(types.string),
    triggerPrice: types.maybeNull(types.string),
    triggerAmount: types.maybeNull(types.string),
    triggerAt: types.maybeNull(types.string),
    triggerFee: types.maybeNull(types.string),
    triggerFeeAsset: types.maybeNull(types.string),
    triggerOrderId: types.maybeNull(types.string),
    profit: types.maybeNull(types.string),
    profitAsset: types.maybeNull(types.string),
    planStopLossPrice: types.maybeNull(types.string),
    stopLossOrderId: types.maybeNull(types.string),
    actualStopLossPrice: types.maybeNull(types.string),
    actualStopLossAmount: types.maybeNull(types.string),
    actualStopLossCoins: types.maybeNull(types.string),
    actualStopLossAt: types.maybeNull(types.string),
    stopLossFee: types.maybeNull(types.string),
    stopLossFeeAsset: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    status: types.maybeNull(types.integer),
    createdTime: types.maybeNull(types.string)
  });