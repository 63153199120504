// assets
import { DashboardOutlined } from '@ant-design/icons';
import {FormattedMessage} from 'react-intl';
import React from 'react';

// icons
const icons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title:  <FormattedMessage id='menu.dashboard' />,
            type: 'item',
            url: '/',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
