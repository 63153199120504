import { types } from 'mobx-state-tree';

export const MaType = types
  .model('MaType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    ma10: types.maybeNull(types.integer),
    ma20: types.maybeNull(types.integer),
    ma30: types.maybeNull(types.integer),
    ma40: types.maybeNull(types.integer),
    ma50: types.maybeNull(types.integer),
    ma60: types.maybeNull(types.integer),
    symbol: types.maybeNull(types.string),
    at: types.maybeNull(types.string),
  });

export const MaInfo = types.model('MaInfo',{
  symbol: types.maybeNull(types.string),
  ma10: types.maybeNull(types.string),
  ma30: types.maybeNull(types.string),
  ma60: types.maybeNull(types.string),
});