import { types } from 'mobx-state-tree';

export const GridType = types
  .model('GridType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    exchangeId: types.maybeNull(types.integer),
    keyId: types.maybeNull(types.integer),
    targetCurrencyId: types.maybeNull(types.integer),
    quoteCurrencyId: types.maybeNull(types.integer),
    type: types.maybeNull(types.integer),
    cost: types.maybeNull(types.number),
    basePrice: types.maybeNull(types.number),
    step: types.maybeNull(types.number),
    profit: types.maybeNull(types.number),
    doneCount: types.maybeNull(types.integer),
    status: types.maybeNull(types.integer),
    increaseType:types.maybeNull(types.integer),
    increase:types.maybeNull(types.integer),
    createdTime: types.maybeNull(types.string),
    profitType: types.maybeNull(types.integer),
    isTrigger: types.maybeNull(types.integer),
    ceil: types.maybeNull(types.string),
    floor: types.maybeNull(types.string),
    autosell: types.maybeNull(types.integer),
    autobuy: types.maybeNull(types.integer),
    direction: types.maybeNull(types.integer),
  });

export const GridSlotType = types
  .model('GridSlotType', {
    id: types.maybeNull(types.integer),
    gridId: types.maybeNull(types.integer),
    buyOrderId: types.maybeNull(types.string),
    sellOrderId: types.maybeNull(types.string),
    slotNbr: types.maybeNull(types.number),
    planBuyPrice: types.maybeNull(types.string),
    planBuyAmount: types.maybeNull(types.string),
    planSellPrice: types.maybeNull(types.string),
    planSellAmount: types.maybeNull(types.string),
    status: types.maybeNull(types.integer),
    actualBuyPrice: types.maybeNull(types.string),
    actualBuyAmount: types.maybeNull(types.string),
    actualSellPrice: types.maybeNull(types.string),
    actualSellAmount: types.maybeNull(types.string),
    buyFee: types.maybeNull(types.string),
    buyFeeAsset: types.maybeNull(types.string),
    sellFee: types.maybeNull(types.string),
    sellFeeAsset: types.maybeNull(types.string),
    profit: types.maybeNull(types.string),
    profitAsset: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    symbol: types.maybeNull(types.string),
    buyAt: types.maybeNull(types.string),
    sellAt: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.string)
  });

  export const GridProfitItemType = types
  .model('GridProfitItemType', {
    count: types.maybeNull(types.integer),
    profit: types.maybeNull(types.string),
    profitAsset: types.maybeNull(types.string),
    currPrice: types.maybeNull(types.string),
  });

  export const GridStaticsType = types
  .model('GridStaticsType', {
    total1: types.array(GridProfitItemType),
    total7: types.array(GridProfitItemType),
    total30: types.array(GridProfitItemType),
    totalAll: types.array(GridProfitItemType),
  });
  

  export const GridRealizedProfitType = types.model('GridRealizedProfitType',{
    realizedProfit: types.maybeNull(types.string),
    profitAsset: types.string
  });

  export const GridProfitType = types
  .model('GridProfitType', {
    realizedProfit: types.array(GridRealizedProfitType),
    totalBuyCost: types.maybeNull(types.string),
    totalBuyAmount: types.maybeNull(types.string),
    totalSellCost: types.maybeNull(types.string),
    totalSellAmount: types.maybeNull(types.string),
    currPrice: types.maybeNull(types.string),
  });

