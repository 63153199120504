import { types } from 'mobx-state-tree';

export const AccountActivityType = types
  .model('AccountActivityType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    type: types.maybeNull(types.integer),
    source: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    ip: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.string)
  });
