// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import {
    Alarm,
    GridOn,
    Sailing,
  } from '@mui/icons-material';
import LandscapeIcon from '@mui/icons-material/Landscape';
import {FormattedMessage} from 'react-intl';
import React from 'react';


// icons
const icons = {
    LoginOutlined,
    ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const products = {
    id: 'products',
    title: <FormattedMessage id='menu.product' />,
    type: 'group',
    children: [
        {
            id: 'aip',
            title: <FormattedMessage id='menu.aip' />,
            type: 'item',
            url: '/job',
            icon: Alarm,
            
        },
        {
            id: 'grid',
            title:  <FormattedMessage id='menu.grid' />,
            type: 'item',
            url: '/grid',
            icon: GridOn,
            
        },
        {
            id: 'piramid',
            title:  <FormattedMessage id='menu.piramid' />,
            type: 'item',
            url: '/piramid',
            icon: LandscapeIcon,
            
        },
        {
            id: 'wave',
            title:  <FormattedMessage id='menu.wave' />,
            type: 'item',
            url: '/wave',
            icon: Sailing,
            
        },
       
    ]
};

export default products;
