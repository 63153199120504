import { types } from 'mobx-state-tree';

export const TotalStatisticsType = types
  .model('TotalStatisticsType', {
    createdTime: types.maybeNull(types.string),
    income: types.maybeNull(types.number)
  });

export const JobStatisticsType = types
  .model('JobStatisticsType', {
    createdTime: types.maybeNull(types.string),
    income: types.maybeNull(types.number)
  });

export const UserStatisticsType = types
  .model('UserStatisticsType', {
    jobCount: types.maybeNull(types.number),
    costTotal: types.maybeNull(types.number),
    timesTotal: types.maybeNull(types.string),
    bigTimesTotal: types.maybeNull(types.string),
    totalCurrentValue: types.maybeNull(types.number),
  });

  export const GridTotalStatisticsType = types
  .model('GridTotalStatisticsType', {
    createdTime: types.maybeNull(types.string),
    profit: types.maybeNull(types.number)
  });
  export const GridStatisticsType = types
  .model('GridStatisticsType', {
    createdTime: types.maybeNull(types.string),
    profit: types.maybeNull(types.number)
  });
  