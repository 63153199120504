import { types } from 'mobx-state-tree';

export const ExchangeType = types
  .model('ExchangeType', {
    id: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    enname: types.maybeNull(types.string),
    ccxtId: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.string)
  });
