import {
  types,
  flow
} from 'mobx-state-tree';
import targetCurrencyService from '../../services/TargetCurrencyService';
import { TargetCurrencyType } from './TargetCurrencyTypes';

export const TargetCurrency = types
  .model('TargetCurrency', {
    currencies: types.optional(types.array(TargetCurrencyType), [])
  })
  .views(self => ({
    getNameByIdAndExchangeId(id, exchangeId) {
      const res = self.currencies.filter(t => t.id === id && t.exchangeId === exchangeId);
      if (res.length > 0) {
        return res[0].name;
      } else {
        return '';
      }
    },
    getUrlByIdAndExchangeId(id, exchangeId) {
      const res = self.currencies.filter(t => t.id === id && t.exchangeId === exchangeId);
      if (res.length > 0) {
        return res[0].url;
      } else {
        return '';
      }
    },
    getUrlByName(Name) {
      const res = self.currencies.filter(t => t.name === name);
      if (res.length > 0) {
        return res[0].url;
      } else {
        return '';
      }
    },
  }))
  
  .actions(self => ({
    async getCurrencies() {
      const res = await targetCurrencyService.getCurrencies();
      return self.getCurrenciesSuccess(res);
    },
    getCurrenciesSuccess(res) {
      if (res) {
        self.currencies = res.data;
      } else {
        self.currencies = [];
      }
    }
  }))
  .actions(self => ({
    async getExchangeCurrencies(params) {
      const res = await targetCurrencyService.getExchangeCurrencies(params);
      return self.getExchangeCurrenciesSuccess(res);
    },
    getExchangeCurrenciesSuccess(res) {
      if (res) {
        self.currencies = res.data;
      } else {
        self.currencies = [];
      }
    }
  }));
