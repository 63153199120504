import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getPrices: (params) =>
    axiosAuth.get(API + '/prices', {params}),
  getPrice: (params) =>
    axiosAuth.get(API + '/price', {params}),
};
