import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getCurrencies: () =>
    axiosAuth.get(API + '/target-currency/all'),
  getExchangeCurrencies: (params) =>
    axiosAuth.get(API + '/target-currency/exchange', {params})
};
