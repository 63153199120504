import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getAllGrids: (params) =>
    axiosAuth.get(API + '/grid/all', {params}),
  getActiveGrids: (params) =>
    axiosAuth.get(API + '/grid/active', {params}),
  getGridDetail: (params) =>
    axiosAuth.get(API + '/grid/detail', {params}),
  getAllGridSlots: (params) =>
    axiosAuth.get(API + '/grid/slot', {params}),
  getActiveGridSlots: (params) =>
    axiosAuth.get(API + '/grid/active-slot', {params}),
  getArchiveGridSlots: (params) =>
    axiosAuth.get(API + '/grid/archive-slot', {params}),
  getRecentGridSlots: (params) =>
    axiosAuth.get(API + '/grid/recent-slot', {params}),
  startGrid: (params) =>
    axiosAuth.get(API + '/grid/start', {params}),
  pauseGrid: (params) =>
    axiosAuth.get(API + '/grid/pause', {params}),
  deleteGrid: (params) =>
    axiosAuth.get(API + '/grid/delete', {params}),
  getGridsByApiKey: (params) =>
    axiosAuth.get(API + '/grid/list', {params}),
  addGrid: (params) =>
    axiosAuth.post(API + '/grid/add', params),
  updateGrid: (params) =>
    axiosAuth.post(API + '/grid/update', params),
  getGridStatics: (params) =>
    axiosAuth.get(API + '/grid/statics', {params}),
  getGridProfit: (params) =>
    axiosAuth.get(API + '/grid/profit', {params}),
  somethingBig: (params) =>
    axiosAuth.post(API + '/grid/something-big', params),
  sellGridSlot: (params) =>
    axiosAuth.post(API + '/grid/sell', params),
};
