import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getAllPiramids: (params) =>
    axiosAuth.get(API + '/piramid/all', {params}),
  getActivePiramids: (params) =>
    axiosAuth.get(API + '/piramid/active', {params}),
  getPiramidDetail: (params) =>
    axiosAuth.get(API + '/piramid/detail', {params}),
  getAllPiramidSlots: (params) =>
    axiosAuth.get(API + '/piramid/slot', {params}),
  startPiramid: (params) =>
    axiosAuth.get(API + '/piramid/start', {params}),
  pausePiramid: (params) =>
    axiosAuth.get(API + '/piramid/pause', {params}),
  deletePiramid: (params) =>
    axiosAuth.get(API + '/piramid/delete', {params}),
  getPiramidsByApiKey: (params) =>
    axiosAuth.get(API + '/piramid/list', {params}),
  addPiramid: (params) =>
    axiosAuth.post(API + '/piramid/add', params),
  updatePiramid: (params) =>
    axiosAuth.post(API + '/piramid/update', params),
  getPiramidProfit: (params) =>
    axiosAuth.get(API + '/piramid/profit', {params}),
  sellPiramidSlot: (params) =>
    axiosAuth.post(API + '/piramid/sell', params),
  
};
