import { useRoutes } from 'react-router-dom';
import {
    observer,
    inject
  } from 'mobx-react';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const Router = (props) => {
    const { store } = props;
    const { token } = store.auth.userInfo;
    
    if (token) {
        return useRoutes([ MainRoutes]);
    }else{
        return useRoutes([ LoginRoutes]);
    }
    
}

export default inject('store')(observer(Router));