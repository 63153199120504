import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
 
  ma: (params) =>
    axiosAuth.get(API + '/wave/ma', {params}),
  getAllWaves: (params) =>
    axiosAuth.get(API + '/wave/all', {params}),
  getActiveWaves: (params) =>
    axiosAuth.get(API + '/wave/active', {params}),
  getWaveDetail: (params) =>
    axiosAuth.get(API + '/wave/detail', {params}),
  getAllWaveTriggers: (params) =>
    axiosAuth.get(API + '/wave/trigger', {params}),
  startWave: (params) =>
    axiosAuth.get(API + '/wave/start', {params}),
  pauseWave: (params) =>
    axiosAuth.get(API + '/wave/pause', {params}),
  deleteWave: (params) =>
    axiosAuth.get(API + '/wave/delete', {params}),
  getWavesByApiKey: (params) =>
    axiosAuth.get(API + '/wave/list', {params}),
  addWave: (params) =>
    axiosAuth.post(API + '/wave/add', params),
  updateWave: (params) =>
    axiosAuth.post(API + '/wave/update', params),
  getWaveProfit: (params) =>
    axiosAuth.get(API + '/wave/profit', {params}),
};
