import { lazy } from 'react';
import React from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

import Error from '../pages/error/Error';

// render - user
const User = Loadable(lazy(() => import('../pages/user/User')));
const ChangePwd = Loadable(lazy(() => import('../pages/user/ChangePwd')));
const TwoFa = Loadable(lazy(() => import('../pages/user/TwoFa')));
const AccountActivity = Loadable(lazy(() => import('../pages/user/AccountActivity')));
// render - job
const Dashboard = Loadable(lazy(() => import('../pages/job/Dashboard')));
const ChooseExchange = Loadable(lazy(() => import('../pages/job/ChooseExchange')));
const HandleKey = Loadable(lazy(() => import('../pages/job/HandleKey')));
const AddJob = Loadable(lazy(() => import('../pages/job/AddJob')));
const UpdateJob = Loadable(lazy(() => import('../pages/job/UpdateJob')));
const JobDetail = Loadable(lazy(() => import('../pages/job/JobDetail')));
const KeyDetail = Loadable(lazy(() => import('../pages/job/KeyDetail')));
const SomethingBig = Loadable(lazy(() => import('../pages/job/SomethingBig')));
// render - grid
const GridDashboard = Loadable(lazy(() => import('../pages/grid/Dashboard')));
const AddGrid = Loadable(lazy(() => import('../pages/grid/AddGrid')));
const GridDetail = Loadable(lazy(() => import('../pages/grid/GridDetail')));
const GridList = Loadable(lazy(() => import('../pages/grid/GridList')));
// render - piramid
const PiramidDashboard = Loadable(lazy(() => import('../pages/piramid/Dashboard')));
const AddPiramid = Loadable(lazy(() => import('../pages/piramid/AddPiramid')));
const PiramidDetail = Loadable(lazy(() => import('../pages/piramid/PiramidDetail')));
const PiramidList = Loadable(lazy(() => import('../pages/piramid/PiramidList')));

const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));

const MaDashboard = Loadable(lazy(() => import('../pages/ma/Dashboard')));
const UpdateMa = Loadable(lazy(() => import('../pages/ma/UpdateMa')));
const AddMa = Loadable(lazy(() => import('../pages/ma/AddMa')));

const WaveDashboard = Loadable(lazy(() => import('../pages/wave/Dashboard')));
const UpdateWave = Loadable(lazy(() => import('../pages/wave/UpdateWave')));
const AddWave = Loadable(lazy(() => import('../pages/wave/AddWave')));
const WaveDetail = Loadable(lazy(() => import('../pages/wave/WaveDetail')));

const Demo = Loadable(lazy(() => import('../pages/demo')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'choose-exchange',
            element: <ChooseExchange />
        },
        
        { path: 'job', element: <Dashboard /> },
        { path: 'handle-key/:exchangeId', element: <HandleKey /> },
        { path: 'add-job/:exchangeId/:keyId', element: <AddJob /> },
        { path: 'update-job/:exchangeId/:keyId/:jobId', element: <UpdateJob /> },
        { path: 'job-detail/:jobId', element: <JobDetail /> },
        { path: 'key-detail/:exchangeId/:keyId', element: <KeyDetail /> },

        { path: 'user', element: <User /> },
        { path: 'change-pwd', element: <ChangePwd /> },
        { path: 'two-fa', element: <TwoFa /> },
        { path: 'account-activity', element: <AccountActivity /> },
        { path: 'something-big/:exchangeId/:keyId/:jobId', element: <SomethingBig /> },

        { path: 'grid', element: <GridDashboard /> },
        { path: 'add-grid/:exchangeId/:keyId', element: <AddGrid /> },
        { path: 'grid-detail/:gridId', element: <GridDetail /> },
        { path: 'grid-list/:exchangeId/:keyId', element: <GridList /> },
        
        { path: 'piramid', element: <PiramidDashboard /> },
        { path: 'add-piramid', element: <AddPiramid /> },
        { path: 'piramid-detail/:piramidId', element: <PiramidDetail /> },
        { path: 'piramid-list/:exchangeId/:keyId', element: <PiramidList /> },

        { path: 'ma', element: <MaDashboard /> },
        { path: 'update-ma/:maId', element: <UpdateMa /> },
        { path: 'add-ma', element: <AddMa /> },
        // { path: 'ma-detail/:maId', element: <MaDetail /> },    
        // { path: 'ma-list', element: <MaList /> },
        
        { path: 'wave', element: <WaveDashboard /> },
        { path: 'update-wave/:waveId', element: <UpdateWave /> },
        { path: 'add-wave', element: <AddWave /> },
        { path: 'wave-detail/:waveId', element: <WaveDetail /> },

        { path: 'demo', element: <Demo /> },
        
    ]
};

export default MainRoutes;
