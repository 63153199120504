const en_US = {
  "menu.dashboard" : "Dashboard" ,
  "menu.aip" : "Auto Invest Plan" ,
  "menu.grid" : "Grid" ,
  "menu.piramid" : "Piramid" ,
  "menu.product" : "Product" ,
  "menu.settings" : "Settings" ,
  "menu.account" : "Account" ,
  "menu.apikey" : "API Keys" ,
  "menu.ma" : "Move Average Subscribe",
  "menu.wave" : "Wave",
    
  "profit" : "Profit",
  "email" : "Email",
  "login" : "Login",
  "register" : "Register",
  "forget":"Forget Password?",
  "password":"Password",
  "invalid.email": "Invalid Email",
  "invalid.password": "Invalid Password",
  "login.success": "Login Sucessfully!",
  "sent.checkcode": "Verification code sent, please check your email",
  "invalid.code": "Invalid Code",
  "password.rexp": "Password Length between 8-32, and must have letters, nunbers and  special characters",
  "password.consist": "Please enter the same password in Confirm password",
  "register.success": "Register successfully!",
  "code": "Code",
  "send": "Send",
  "confirm.password":"Confirm Password",
  "reset.password.success": "Reset Password Successfully!",
  "new.password":"New Password",
  "submit": "Submit",

  "job.profit" : "Job Profit",
  "grid.weekly.profit" : "Grid Weekly Profit",
  "market.overview" : "Market Overview",

}    
export default en_US;