import React, { useState } from 'react';
import {
  observer,
  inject
} from 'mobx-react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const Snack = (props) => {
  const { store } = props;
  const { snack } = store.common;

  if (!snack) {
    return null;
  }

  return (
    <Snackbar
      key={snack.message}
      open={snack.open}
      anchorOrigin={{
        vertical: snack.vertical || 'top',
        horizontal: snack.horizontal || 'center',
      }}
      autoHideDuration={3000}
      onClose={() => {
        store.common.setSnack({
          open: false
        });
      }}
    >
      <Alert
        variant="filled"
        severity={snack.type}
      >
        {snack.message}
      </Alert>
    </Snackbar>
  );
};

export default inject('store')(observer(Snack));
