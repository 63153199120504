// project import
import products from './products';
import dashboard from './dashboard';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, products, settings]
};

export default menuItems;
