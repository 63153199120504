import {
  types,
  flow
} from 'mobx-state-tree';
import gridService from '../../services/GridService';
import { GridType, GridSlotType,GridStaticsType,GridProfitType } from './GridTypes';

export const Grid = types
  .model('Grid', {
    grids: types.optional(types.array(GridType), []),
    activeGrids: types.optional(types.array(GridType), []),
    gridTotalPages: types.optional(types.number, 0),
    gridDetail: types.maybe(GridType),
    gridSlots: types.optional(types.array(GridSlotType), []),
    gridArchiveSlots: types.optional(types.array(GridSlotType), []),
    gridRecentSlots: types.optional(types.array(GridSlotType), []),
    gridStatics: types.optional(types.array(GridStaticsType), []),
    gridSlotTotalPages: types.optional(types.number, 0),
    gridProfit: types.optional(types.array(GridProfitType), []),
  })
  .actions(self => ({
    async getAllGrids(params) {
      const res = await gridService.getAllGrids(params);
      return self.getAllGridsSuccess(res);
    },
    getAllGridsSuccess(res) {
      if (res) {
        self.gridTotalPages = res.data.totalPages;
        self.grids = res.data.list;
      } else {
        self.grids = [];
      }
    }
  }))
  .actions(self => ({
    async getActiveGrids(params) {
      const res = await gridService.getActiveGrids(params);
      return self.getActiveGridsSuccess(res);
    },
    getActiveGridsSuccess(res) {
      if (res) {
        // self.gridTotalPages = res.data.totalPages;
        self.activeGrids = res.data.list;
      } else {
        self.activeGrids = [];
      }
    }
  }))
  .actions(self => ({
    async getGridsByApiKey(params) {
      const res = await gridService.getGridsByApiKey(params);
      return self.getGridsByApiKeySuccess(res);
    },
    getGridsByApiKeySuccess(res) {
      if (res) {
        self.gridTotalPages = res.data.totalPages;
        self.grids = res.data.list;
      } else {
        self.grids = [];
      }
    }
  }))
  .actions(self => ({
    async getGridDetail(params) {
      const res = await gridService.getGridDetail(params);
      return self.getGridDetailSuccess(res);
    },
    getGridDetailSuccess(res) {
      if (res) {
        self.gridDetail = res.data;
      } else {
        self.gridDetail = null;
      }
    }
  }))
  .actions(self => ({
    async getAllGridSlots(params) {
      const res = await gridService.getAllGridSlots(params);
      return self.getAllGridSlotsSuccess(res);
    },
    getAllGridSlotsSuccess(res) {
      if (res) {
        self.gridSlots = res.data.list;
      } else {
        self.gridSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getActiveGridSlots(params) {
      const res = await gridService.getActiveGridSlots(params);
      return self.getActiveGridSlotsSuccess(res);
    },
    getActiveGridSlotsSuccess(res) {
      if (res) {
        self.gridSlots = res.data.list;
      } else {
        self.gridSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getRecentGridSlots(params) {
      const res = await gridService.getRecentGridSlots(params);
      return self.getRecentGridSlotsSuccess(res);
    },
    getRecentGridSlotsSuccess(res) {
      if (res) {
        self.gridRecentSlots = res.data.list;
      } else {
        self.gridRecentSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getArchiveGridSlots(params) {
      const res = await gridService.getArchiveGridSlots(params);
      return self.getArchiveGridSlotsSuccess(res);
    },
    getArchiveGridSlotsSuccess(res) {
      if (res) {
        self.gridArchiveSlots = res.data.list;
      } else {
        self.gridArchiveSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getGridStatics(params) {
      
      const res = await gridService.getGridStatics(params);
      return self.getGridStaticsSuccess(res);
    },
    getGridStaticsSuccess(res) {
      if (res) {
        self.gridStatics = res.data;
      } else {
        self.gridStatics = [];
      }
    }
  }))
  .actions(self => ({
    async getGridProfit(params) {
      
      const res = await gridService.getGridProfit(params);
      return self.getGridProfitSuccess(res);
    },
    getGridProfitSuccess(res) {
      if (res) {
        self.gridProfit = res.data;
      } else {
        self.gridProfit = [];
      }
    }
  }))
  .actions(self => ({
    async startGrid(params) {
      return await gridService.startGrid(params);
    },

    async pauseGrid(params) {
      return await gridService.pauseGrid(params);
    },

    async deleteGrid(params) {
      return await gridService.deleteGrid(params);
    },

    async addGrid(params) {
      return await gridService.addGrid(params);
    },

    async updateGrid(params) {
      return await gridService.updateGrid(params);
    },

    async somethingBig(params) {
      return await gridService.somethingBig(params);
    },
    async sellGridSlot(params) {
      return await gridService.sellGridSlot(params);
    }
  }));
