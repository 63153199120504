import {
  types,
  flow
} from 'mobx-state-tree';
import maService from '../../services/MaService';
import { MaType, MaInfo } from './MaTypes';

export const Ma = types
  .model('Ma', {
    mas: types.optional(types.array(MaType), []),
    ma: types.maybe(MaType),
    maInfos: types.optional(types.array(MaInfo), []),
  })
  .actions(self => ({
    async getAllMas(params) {
      const res = await maService.getAllMas(params);
      return self.getAllMasSuccess(res);
    },
    getAllMasSuccess(res) {
      if (res) {
        self.mas = res.data.list;
      } else {
        self.mas = [];
      }
    }
  }))
  .actions(self => ({
    async getMaDetail(params) {
      const res = await maService.getMaDetail(params);
      return self.getMaSuccess(res);
    },
    getMaSuccess(res) {
      if (res) {
        self.ma = res.data;
      } else {
        self.ma = [];
      }
    }
  }))
  .actions(self => ({
    async getMaInfo(params) {
      const res = await maService.getMaInfo(params);
      return self.getMaInfoSuccess(res);
    },
    getMaInfoSuccess(res) {
      if (res) {
        self.maInfos = res.data.list;
      } else {
        self.maInfos = [];
      }
    }
  }))
  .actions(self => ({
  
    async deleteMa(params) {
      return await maService.deleteMa(params);
    },

    async addMa(params) {
      return await maService.addMa(params);
    },

    async updateMa(params) {
      return await maService.updateMa(params);
    },

  }));
