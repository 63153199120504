import {
  types,
  flow
} from 'mobx-state-tree';
import exchangeService from '../../services/ExchangeService';
import { ExchangeType } from './ExchangeTypes';

export const Exchange = types
  .model('Exchange', {
    exchanges: types.optional(types.array(ExchangeType), [])
  })
  .views(self => ({
    getNameById(id) {
      const res = self.exchanges.filter(t => t.id === id);
      if (res.length > 0) {
        return res[0].name;
      } else {
        return '';
      }
    },
    getEnNameById(id) {
      const res = self.exchanges.filter(t => t.id === id);
      if (res.length > 0) {
        return res[0].enname;
      } else {
        return '';
      }
    },
  }))
  .actions(self => ({
    async getExchanges() {
      const res = await exchangeService.getExchanges();
      return self.getExchangesSuccess(res);
    },
    getExchangesSuccess(res) {
      if (res) {
        self.exchanges = res.data;
      } else {
        self.exchanges = [];
      }
    }
  }));
