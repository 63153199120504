// assets
import {
    Menu,
    Person,
    ExitToApp,
    Alarm,
    HistoryEdu,
    GridOn,
    Key
  } from '@mui/icons-material';
  import {FormattedMessage} from 'react-intl';
  import React from 'react';
  
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const settings = {
    id: 'settings',
    title: <FormattedMessage id='menu.settings' />,
    type: 'group',
    children: [
        {
            id: 'account',
            title: <FormattedMessage id='menu.account' />,
            type: 'item',
            url: '/user',
            icon: Person
        },
        {
            id: 'apikey',
            title: <FormattedMessage id='menu.apikey' />,
            type: 'item',
            url: '/choose-exchange',
            icon: Key
        },
        {
            id: 'ma',
            title: <FormattedMessage id='menu.ma' defaultMessage={'Move Average Subscribe'}/>,
            type: 'item',
            url: '/ma',
            icon: Key
        },
        
    ]
};

export default settings;
