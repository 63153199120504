import {
  types,
} from 'mobx-state-tree';
import priceService from '../../services/PriceService';
import { PriceType } from './PriceTypes';

export const Price = types
  .model('Price', {
    prices: types.optional(types.array(PriceType), []),
  })
  .actions(self => ({
    async getPrice(params) {
      const res = await priceService.getPrice(params);
      return self.getAllPriceSuccess(res);
    },
    getAllPriceSuccess(res) {
      if (res) {
        self.prices = res.data.list;
      } else {
        self.prices = [];
      }
    }
  }))
  .actions(self => ({
    async getPrices(params) {
      const res = await priceService.getPrices(params);
      return self.getAllPricesSuccess(res);
    },
    getAllPricesSuccess(res) {
      if (res) {
        self.prices = res.data;
      } else {
        self.prices = [];
      }
    }
  }));
