import {
  types,
} from 'mobx-state-tree';

import { WsPriceType } from './WsTypes';

export const Ws = types
  .model('Ws', {
    wsPrice: types.optional(types.array(WsPriceType), []),
  })
  .actions(self => ({
    async setWsPrice(data) {
      self.wsPrice = data;
    }
  }))
