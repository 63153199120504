import { axiosAuth, axiosOpen } from '../utils/axios';

const API = process.env.API;

export default {
  changePwd: (params) =>
    axiosAuth.post(API + '/user/change-pwd', params),
  generateTwoFa: (params) =>
    axiosAuth.get(API + '/user/two-fa/generate', {params}),
  handleTwoFa: (params) =>
    axiosAuth.post(API + '/user/two-fa/handle', params),
  getAccountActivities: (params) =>
    axiosAuth.get(API + '/user/account-activities', {params})
};
