import { types } from 'mobx-state-tree';
import { TargetCurrencyType } from '../targetCurrency/TargetCurrencyTypes';

export const JobType = types
  .model('JobType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    exchangeId: types.maybeNull(types.integer),
    keyId: types.maybeNull(types.integer),
    type: types.maybeNull(types.integer),
    targetCurrencyId: types.maybeNull(types.integer),
    cost: types.maybeNull(types.number),
    rule: types.maybeNull(types.integer),
    ruleDay: types.maybeNull(types.string),
    ruleTime: types.maybeNull(types.string),
    totalTarget: types.maybeNull(types.number),
    totalCost: types.maybeNull(types.number),
    executionTimes: types.maybeNull(types.integer),
    executionTimesBig: types.maybeNull(types.integer),
    status: types.maybeNull(types.integer),
    createdTime: types.maybeNull(types.string),
    autoPause: types.maybeNull(types.integer),
    autoPauseValue: types.maybeNull(types.string),
    dryRun: types.maybeNull(types.integer),
    currency: types.maybeNull(TargetCurrencyType),
  });

export const JobLogType = types
  .model('JobLogType', {
    id: types.maybeNull(types.integer),
    jobId: types.maybeNull(types.integer),
    orderId: types.maybeNull(types.string),
    cost: types.maybeNull(types.number),
    amount: types.maybeNull(types.number),
    fee: types.maybeNull(types.number),
    feeAsset: types.maybeNull(types.string),
    status: types.maybeNull(types.integer),
    type: types.maybeNull(types.integer),
    comment: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.string)
  });
