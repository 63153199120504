import { types } from 'mobx-state-tree';
import { ExchangeType } from '../exchange/ExchangeTypes';

export const TargetCurrencyType = types
  .model('TargetCurrencyType', {
    id: types.maybeNull(types.integer),
    exchangeId: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    exchange: types.maybeNull(ExchangeType),
  });

