import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { CssBaseline } from '@mui/material';

import { Provider as ReduxProvider } from 'react-redux';

import { store } from './store';

import Intl from './Intl';

ReactDOM.render(
  <ReduxProvider store={store}>
  <BrowserRouter>
    <CssBaseline>
      <Intl>
        <App />
      </Intl>
    </CssBaseline>
  </BrowserRouter></ReduxProvider>,
  document.getElementById('root'),
);
