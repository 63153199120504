import { types } from 'mobx-state-tree';
import { ExchangeType } from '../exchange/ExchangeTypes';
export const ApiKeyType = types
  .model('ApiKeyType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    exchangeId: types.maybeNull(types.integer),
    keyString: types.maybeNull(types.string),
    secret: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    memo: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.string),
    exchange: types.maybeNull(ExchangeType),
  });
