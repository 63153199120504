import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getAllMas: (params) =>
    axiosAuth.get(API + '/ma/all', {params}),
  getMaDetail: (params) =>
    axiosAuth.get(API + '/ma/detail', {params}),
  getMaInfo: (params) =>
    axiosAuth.get(API + '/ma/info', {params}),
 
  deleteMa: (params) =>
    axiosAuth.get(API + '/ma/delete', {params}),
  addMa: (params) =>
    axiosAuth.post(API + '/ma/add', params),
  updateMa: (params) =>
    axiosAuth.post(API + '/ma/update', params),
  
};
