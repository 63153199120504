import {
  types,
  flow
} from 'mobx-state-tree';
import userService from '../../services/UserService';
import { AccountActivityType } from './UserTypes';

export const User = types
  .model('User', {
    activities: types.optional(types.array(AccountActivityType), []),
    activityTotalPages: types.optional(types.number, 0)
  })
  .actions(self => ({
    async changePwd(params) {
      return await userService.changePwd(params);
    },

    async generateTwoFa(params) {
      return await userService.generateTwoFa(params);
    },

    async handleTwoFa(params) {
      return await userService.handleTwoFa(params);
    },

    async getAccountActivities(params) {
      const res = await userService.getAccountActivities(params);
      return self.getAccountActivitiesSuccess(res);
    },

    getAccountActivitiesSuccess(res) {
      if (res) {
        self.activityTotalPages = res.data.totalPages;
        self.activities = res.data.list;
      } else {
        self.activities = [];
      }
    }
  }));
