import { types } from 'mobx-state-tree';

export const UserInfoType = types
  .model('UserInfoType', {
    token: types.maybeNull(types.string),
    uuid: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    pwdLevel: types.maybe(types.integer),
    twoFa: types.maybe(types.integer),
    scope: types.maybe(types.integer),
    grid: types.maybeNull(types.integer),
    createdTime: types.maybeNull(types.string)
  });
