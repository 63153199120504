import { axiosAuth, axiosOpen } from '../utils/axios';

const API = process.env.API;

export default {
  register: (params) =>
    axiosOpen.post(API + '/auth/register', params),
  emailCodeForRegister: (params) =>
    axiosOpen.get(API + '/auth/email-code/register', {params}),
  login: (params) =>
    axiosOpen.post(API + '/auth/login', params),
  forget: (params) =>
    axiosOpen.post(API + '/auth/forget', params),
  emailCodeForForget: (params) =>
    axiosOpen.get(API + '/auth/email-code/forget', {params}),
  safetyCheck: (params) =>
    axiosOpen.post(API + '/auth/safety-check', params)
};
