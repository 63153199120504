import {
  types,
  flow
} from 'mobx-state-tree';
import commonService from '../../services/CommonService';
import {
  SnackType,
  CurrencyPriceType
} from './CommonTypes';

export const Common = types
  .model('Common', {
    snack: types.optional(SnackType, {}),
    notes: types.optional(types.array(types.string), []),
    currencyPrice: types.maybe(CurrencyPriceType)
  })
  .actions(self => ({
    async setNotes(data) {
      self.notes = data;
    },

    setSnack(data) {
      self.snack = data;
    }
  }))
  .actions(self => ({
    async getCurrencyPrice(params) {
      const res = await commonService.getCurrencyPrice(params);
      return self.getCurrencyPriceSuccess(res, params);
    },
    getCurrencyPriceSuccess(res, params) {
      if (res.status === 200) {
        self.currencyPrice = res.data;
      } else {
        self.currencyPrice = {
          price: '0',
          symbol: params.symbol
        };
      }
    }
  }));
