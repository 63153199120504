import { types } from 'mobx-state-tree';

export const PriceType = types
  .model('PriceType', {
    id: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    quoteName: types.maybeNull(types.string),
    quote: types.maybeNull(types.number),
    ma10: types.maybeNull(types.string),
    ma30: types.maybeNull(types.string),
    ma60: types.maybeNull(types.string),
    at: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
  });

