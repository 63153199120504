import {
  types,
  flow
} from 'mobx-state-tree';
import apiKeyService from '../../services/ApiKeyService';
import { ApiKeyType } from './ApiKeyTypes';

export const ApiKey = types
  .model('ApiKey', {
    apiKeys: types.optional(types.array(ApiKeyType), []),
    apiKeyTotalPages: types.optional(types.number, 0)
  })
  .views(self => ({
    getKeyById(id) {
      const res = self.apiKeys.filter(t => t.id === id);
      if (res.length > 0) {
        return res[0].keyString;
      } else {
        return '';
      }
    }
  }))
  .actions(self => ({
    async getAllApiKeys(params) {
      const res = await apiKeyService.getAllApiKeys(params);
      return self.getAllApiKeysSuccess(res);
    },
    getAllApiKeysSuccess(res) {
      if (res) {
        self.apiKeys = res.data;
      } else {
        self.apiKeys = [];
      }
    },
    async getExchangeApiKeys(params) {
      const res = await apiKeyService.getExchangeApiKeys(params);
      return self.getExchangeApiKeysSuccess(res);
    },
    getExchangeApiKeysSuccess(res) {
      if (res) {
        self.apiKeyTotalPages = res.data.totalPages;
        self.apiKeys = res.data.list;
      } else {
        self.apiKeys = [];
      }
    },
  }))
  .actions(self => ({
    async addApiKey(params) {
      return await apiKeyService.addApiKey(params);
    },

    async updateApiKey(params) {
      return await apiKeyService.updateApiKey(params);
    },

    async deleteApiKey(params) {
      return await apiKeyService.deleteApiKey(params);
    }

  }));
