import { types } from 'mobx-state-tree';
import { TargetCurrencyType } from '../targetCurrency/TargetCurrencyTypes';
export const PiramidType = types
  .model('PiramidType', {
    id: types.maybeNull(types.integer),
    userUuid: types.maybeNull(types.string),
    exchangeId: types.maybeNull(types.integer),
    keyId: types.maybeNull(types.integer),
    targetCurrencyId: types.maybeNull(types.integer),
    type: types.maybeNull(types.integer),
    cost: types.maybeNull(types.number),
    basePrice: types.maybeNull(types.number),
    step: types.maybeNull(types.number),
    increaseType: types.maybeNull(types.integer),
    increase: types.maybeNull(types.integer),
    status: types.maybeNull(types.integer),
    totalSteps: types.maybeNull(types.integer),
    maxAmount:  types.maybeNull(types.string),
    direction: types.maybeNull(types.integer),
    orderType: types.maybeNull(types.integer),
    createdTime: types.maybeNull(types.string),
    currency: types.maybeNull(TargetCurrencyType),
  });

export const PiramidSlotType = types
  .model('PiramidSlotType', {
    id: types.maybeNull(types.integer),
    piramidId: types.maybeNull(types.integer),
    orderId: types.maybeNull(types.string),
    slotNbr: types.maybeNull(types.number),
    planPrice: types.maybeNull(types.string),
    planAmount: types.maybeNull(types.string),
    status: types.maybeNull(types.integer),
    actualPrice: types.maybeNull(types.string),
    actualAmount: types.maybeNull(types.string),
    fee: types.maybeNull(types.string),
    feeAsset: types.maybeNull(types.string),
    
    planSellPrice: types.maybeNull(types.string),
    actualSellPrice: types.maybeNull(types.string),
    actualSellAmount: types.maybeNull(types.string),
    sellFee: types.maybeNull(types.string),
    sellFeeAsset: types.maybeNull(types.string),
    sellAt:types.maybeNull(types.string),
    profit: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    execAt: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.string)
  });

  export const PiramidProfitType = types
  .model('PiramidProfitType', {
    totalCost: types.maybeNull(types.number),
    totalAmount: types.maybeNull(types.number), 
    totalProfit: types.maybeNull(types.number), 
    currPrice: types.maybeNull(types.string),
  });
