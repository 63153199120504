import {
  types,
  onSnapshot,
  flow
} from 'mobx-state-tree';
import Keys from '../config/Keys';
import { resetAxiosAuth } from '../utils/axios';
import { Common as common } from './common';
import { Auth as auth } from './auth';
import { Job as job } from './job';
import { Grid as grid } from './grid';
import { Piramid as piramid } from './piramid';
import { Price as price } from './price';
import { Statistics as statistics } from './statistics';
import { Exchange as exchange } from './exchange';
import { TargetCurrency as targetCurrency } from './targetCurrency';
import { ApiKey as apiKey } from './apiKey';
import { User as user } from './user';
import { Wave as wave} from './wave';
import { Ma as ma} from './ma';
import { Ws as ws} from './ws';

const WS = process.env.WS;

export const Store = types
  .model('Store', {
    common,
    auth,
    job,
    grid,
    piramid,
    statistics,
    exchange,
    targetCurrency,
    apiKey,
    user,
    price,
    wave,
    ma,
    ws,
  })
  .actions(self => ({
    makeInit: flow(function* () {
      let result = false;

      yield makeNotes();
      yield makeAccount();
      setupWebSocket();
      resetAxiosAuth();

      result = true;

      return result;
    }),
  }))
  .create({
    common: {},
    auth: {},
    job: {},
    grid: {},
    piramid: {},
    statistics: {},
    exchange: {},
    targetCurrency: {},
    apiKey: {},
    user: {},
    price: {},
    wave: {},
    ma: {},
    ws: {},
  });

export const createStore = () => {
  return Store.makeInit();
};

const makeNotes = flow(function* () {
  const data = [
    '包含着某些真理因素的谬误是最危险的。',
    '工资是劳动的报酬，利润是节欲的报酬。',
    '财富的生产力比之财富本身，不知道要重要多少倍。',
    '别想一下就造出大海，必须先由小河川开始。',
    '不要把所有的鸡蛋放在同一个篮子里。',
    '一切经济最后都归结为时间经济。',
    '经济波动是无规律的且不可预测的。',
    '一切商品都是暂时的货币；货币是永久的商品。',
    '本钱像酵母，缺了它就发不起面来。',
    '资本因节约而增加，因消费与失策而减少。',
    '经济上的自由，才是真正的自由。',
    '交换倾向出于自利的动机，并且引发了分工。',
    '仓廪实则知礼节，衣食足则知荣辱。',
    '必知富之事，然后能富。',
    '在资本主义社会中，经济进步意味着动荡。',
    '经济决定你能承载怎样的生活。',
    '风险与知识呈反向变化。'
  ];

  yield Store.common.setNotes(data);
});

const makeAccount = flow(function* () {
  const data = window.localStorage.getItem(`Yakult_Account_${Keys.storeVersion}`);
  if (data !== null) {
    yield Store.auth.updateAccount(JSON.parse(data));
  }
});

const setupWebSocket =  () => {
  
    let socket = new WebSocket(WS);
    socket.onclose = () => setTimeout(() => setupWebSocket(), 5000);
    socket.onmessage = evt => {
      const newPrices = JSON.parse(evt.data);
      Store.ws.setWsPrice(newPrices);
    };
    
};

onSnapshot(Store.auth.userInfo, (snapshot) => {
  if (snapshot.token) {
    window.localStorage.setItem(
      `Yakult_Account_${Keys.storeVersion}`,
      JSON.stringify(snapshot)
    );
  }
});
