import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getAllJobs: (params) =>
    axiosAuth.get(API + '/job/all', {params}),
  getJobDetail: (params) =>
    axiosAuth.get(API + '/job/detail', {params}),
  getAllJobLogs: (params) =>
    axiosAuth.get(API + '/job/log', {params}),
  startJob: (params) =>
    axiosAuth.get(API + '/job/start', {params}),
  pauseJob: (params) =>
    axiosAuth.get(API + '/job/pause', {params}),
  deleteJob: (params) =>
    axiosAuth.get(API + '/job/delete', {params}),
  getJobsByApiKey: (params) =>
    axiosAuth.get(API + '/job/api-key', {params}),
  addJob: (params) =>
    axiosAuth.post(API + '/job/add', params),
  updateJob: (params) =>
    axiosAuth.post(API + '/job/update', params),
  somethingBig: (params) =>
    axiosAuth.post(API + '/job/something-big', params)
};
