import { types } from 'mobx-state-tree';
import { ExchangeType } from '../exchange/ExchangeTypes';

export const WsPriceType = types
  .model('WsPriceType', {
    id: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    quoteName: types.maybeNull(types.string),
    quote: types.maybeNull(types.number),
    exchange: types.maybeNull(ExchangeType),
    at: types.maybeNull(types.string),
  })
