import {
  types,
  flow
} from 'mobx-state-tree';
import jobService from '../../services/JobService';
import { JobType, JobLogType } from './JobTypes';

export const Job = types
  .model('Job', {
    jobs: types.optional(types.array(JobType), []),
    jobTotalPages: types.optional(types.number, 0),
    jobDetail: types.maybeNull(JobType),
    jobLogs: types.optional(types.array(JobLogType), []),
    jobLogTotalPages: types.optional(types.number, 0)
  })
  .actions(self => ({
    async getAllJobs(params) {
      const res = await jobService.getAllJobs(params);
      return self.getAllJobsSuccess(res);
    },
    getAllJobsSuccess(res) {
      if (res) {
        self.jobTotalPages = res.data.totalPages;
        self.jobs = res.data.list;
      } else {
        self.jobs = [];
      }
    }
  }))
  .actions(self => ({
    async getJobsByApiKey(params) {
      const res = await jobService.getJobsByApiKey(params);
      return self.getJobsByApiKeySuccess(res);
    },
    getJobsByApiKeySuccess(res) {
      if (res) {
        self.jobTotalPages = res.data.totalPages;
        self.jobs = res.data.list;
      } else {
        self.jobs = [];
      }
    }
  }))
  .actions(self => ({
    async getJobDetail(params) {
      const res = await jobService.getJobDetail(params);
      return self.getJobDetailSuccess(res);
    },
    getJobDetailSuccess(res) {
      if (res) {
        self.jobDetail = res.data;
      } else {
        self.jobDetail = null;
      }
    }
  }))
  .actions(self => ({
    async getAllJobLogs(params) {
      const res = await jobService.getAllJobLogs(params);
      return self.getAllJobLogsSuccess(res);
    },
    getAllJobLogsSuccess(res) {
      if (res) {
        self.jobLogTotalPages = res.data.totalPages;
        self.jobLogs = res.data.list;
      } else {
        self.jobLogs = [];
      }
    }
  }))
  .actions(self => ({
    async startJob(params) {
      return await jobService.startJob(params);
    },

    async pauseJob(params) {
      return await jobService.pauseJob(params);
    },

    async deleteJob(params) {
      return await jobService.deleteJob(params);
    },

    async addJob(params) {
      return await jobService.addJob(params);
    },

    async updateJob(params) {
      return await jobService.updateJob(params);
    },

    async somethingBig(params) {
      return await jobService.somethingBig(params);
    }
  }));
