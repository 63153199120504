import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getAllApiKeys: (params) =>
    axiosAuth.get(API + '/api-key/all', {params}),
  getExchangeApiKeys: (params) =>
    axiosAuth.get(API + '/api-key/exchange', {params}),
  addApiKey: (params) =>
    axiosAuth.post(API + '/api-key/add', params),
  updateApiKey: (params) =>
    axiosAuth.post(API + '/api-key/update', params),
  deleteApiKey: (params) =>
    axiosAuth.get(API + '/api-key/delete', {params})
};
