import { lazy } from 'react';
import React from 'react';

// project import
import Loadable from '../components/Loadable';

import MinimalLayout from '../layout/MinimalLayout';

import Error from '../pages/error/Error';


// render - login
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const Forget = Loadable(lazy(() => import('../pages/auth/Forget')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'register',
            element: <Register />
        },
        {
            path: 'forget',
            element: <Forget />
        },
        {
            path: '*',
            element: <Error />
        },
    ]
};

export default LoginRoutes;
