import React from 'react';
import {
  Container,
  Button
} from '@mui/material';
import Images from '../../assets';

function Error() {
  return (
    <Container
      className="error-container"
      component="main"
      maxWidth="xs"
    >
      <img
        className="gray-logo"
        src={Images.logo}
      />
      <div className="code">404</div>
      <div className="text">找不到页面</div>
      <Button
        variant="outlined"
        color="primary"
        href="/"
      >
        返回首页
      </Button>
    </Container>
  );
};

export default Error;
