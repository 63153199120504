import { Outlet } from 'react-router-dom';
import {
    observer,
    inject
  } from 'mobx-react';
  import React from 'react';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Outlet />
    </>
);

export default MinimalLayout;