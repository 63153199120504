import {
  types,
  flow
} from 'mobx-state-tree';
import Keys from '../../config/Keys';
import { resetAxiosAuth } from '../../utils/axios';
import authService from '../../services/AuthService';
import { UserInfoType } from './AuthTypes';


export const Auth = types
  .model('Auth', {
    userInfo: types.optional(UserInfoType, {}),
  })
  .views(self => ({
    get isAuthorized() {
      return !!self.userInfo.token;
    },
  }))
  .actions(self => ({
    async updateAccount(data) {
      self.userInfo = data;
      resetAxiosAuth();
    },
    checkNeedLogin(redirectTo) {
      if (!self.isAuthorized) {
        window.location.href='/';
      }
      return !self.isAuthorized;
    },
    unauthorized(jumpToLogin) {
      self.removeAccount();

      if (jumpToLogin) {
        window.location.href='/';
      }
    },
    async removeAccount() {
      self.userInfo = {};
      window.localStorage.removeItem(`Yakult_Account_${Keys.storeVersion}`);
      resetAxiosAuth();
    },
  }))
  .actions(self => ({
    async register(params) {
      return await authService.register(params);
    },

    async emailCodeForRegister(params) {
      return await authService.emailCodeForRegister(params);
    },

    async login(params) {
      return await authService.login(params);
    },

    async forget(params) {
      return await authService.forget(params);
    },

    async emailCodeForForget(params) {
      return await authService.emailCodeForForget(params);
    },

    async safetyCheck(params) {
      return await authService.safetyCheck(params);
    },

    logout(navigate) {
      navigate('/');
      self.removeAccount();
    }
  }));
