const initialState = {
  language: window.localStorage.getItem('locale')
}
const getRootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return { language: action.val }
    default:
      return state
  }
};

export default getRootReducer;