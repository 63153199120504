import { types } from 'mobx-state-tree';

export const MaType = types
  .model('MaType', {
    t: types.maybeNull(types.string),
    ma60: types.maybeNull(types.number),
    ma50: types.maybeNull(types.number),
    ma40: types.maybeNull(types.number),
    ma30: types.maybeNull(types.number),
    ma20: types.maybeNull(types.number),
    ma10: types.maybeNull(types.number),
    
  });
