import React, { useState, useEffect } from 'react';
import { Provider } from 'mobx-react';
import {
  Store,
  createStore
} from './stores';
import './styles/index.scss';
import Snack from './components/common/Snack';
import Router from './router';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  LinearProgress
} from '@mui/material';

import ThemeCustomization from './themes';

function App() {
  const [storeRes, setStoreRes] = useState();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3f72af',
        contrastText: '#fff'
      },
      cancel: {
        main: '#e0e0e0',
        contrastText: '#000'
      },
      gray: {
        main: '#555',
        contrastText: '#fff'
      }
    },
  });

  useEffect(() => {
    (async function () {
      try {
        const res = await createStore();
        setStoreRes(res);
      } catch(e) {
        setStoreRes(false);
      }
    })();
  }, []);

  if(!storeRes) {
    return (
      <LinearProgress />
    );
  }



  return (
    <StyledEngineProvider injectFirst>
      {/* <ThemeProvider theme={theme}> */}
      <ThemeCustomization>
        <Provider store={Store}>
          <Router />
          <Snack />
        </Provider>
        </ThemeCustomization>
      {/* </ThemeProvider> */}
    </StyledEngineProvider>
  );
}


export default App;
