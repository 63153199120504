const zh_CN = {
  "menu.dashboard" : "仪表盘" ,
  "menu.aip" : "定投" ,
  "menu.grid" : "网格" ,
  "menu.piramid" : "金字塔" ,
  "menu.product" : "产品" ,
  "menu.settings" : "设置" ,
  "menu.account" : "账户" ,
  "menu.apikey" : "API Keys" ,
  "menu.ma" : "Move Average Subscribe",
  "menu.wave" : "逐浪",
  
  "profit" : "收益",
  "email" : "邮箱",
  "login" : "登录",
  "register" : "注册",
  "forget":"忘记密码?",
  "password":"密码",
  "invalid.email": "无效Email",
  "invalid.password": "无效密码",
  "login.success": "登录成功!",
  "sent.checkcode": "验证码发送成功，请检查邮件",
  "invalid.code": "无效验证码",
  "password.rexp": "请设置密码为8～32位的数字、字母、符号",
  "password.consist": "请确认两次密码输入一致",
  "register.success": "注册成功!",
  "code": "验证码",
  "send": "发送",
  "confirm.password":"确认密码",
  "reset.password.success": "重置密码成功!",
  "new.password":"新密码",
  "submit": "提交",

  "job.profit" : "定投利润",
  "grid.weekly.profit" : "网格周利润",
  "market.overview" : "市场概况",
}
export default zh_CN; 