import { axiosAuth } from '../utils/axios';

const API = process.env.API;

export default {
  getTotal: (params) =>
    axiosAuth.get(API + '/statistics/total', {params}),
  getJob: (params) =>
    axiosAuth.get(API + '/statistics/job', {params}),
  getUser: (params) =>
    axiosAuth.get(API + '/statistics/user', {params}),
  getGridTotal: (params) =>
    axiosAuth.get(API + '/statistics/gridtotal', {params}),
  getGirdJob: (params) =>
    axiosAuth.get(API + '/statistics/grid', {params}),
    
};
