import {
  types,
  flow
} from 'mobx-state-tree';
import piramidService from '../../services/PiramidService';
import { PiramidType,PiramidSlotType,PiramidProfitType } from './PiramidTypes';

export const Piramid = types
  .model('Piramid', {
    piramids: types.optional(types.array(PiramidType), []),
    activePiramids: types.optional(types.array(PiramidType), []),
    piramidTotalPages: types.optional(types.number, 0),
    piramidDetail: types.maybe(PiramidType),
    piramidPrice: types.optional(types.string,""),
    piramidSlots: types.optional(types.array(PiramidSlotType), []),
    piramidSlotTotalPages: types.optional(types.number, 0),
    piramidProfit: types.optional(types.array(PiramidProfitType), []),
    
  })
  .actions(self => ({
    async getAllPiramids(params) {
      const res = await piramidService.getAllPiramids(params);
      return self.getAllPiramidsSuccess(res);
    },
    getAllPiramidsSuccess(res) {
      if (res) {
        self.piramidTotalPages = res.data.totalPages;
        self.piramids = res.data.list;
      } else {
        self.piramids = [];
      }
    }
  }))
  .actions(self => ({
    async getActivePiramids(params) {
      const res = await piramidService.getActivePiramids(params);
      return self.getActivePiramidsSuccess(res);
    },
    getActivePiramidsSuccess(res) {
      if (res) {
        // self.piramidTotalPages = res.data.totalPages;
        self.activePiramids = res.data.list;
      } else {
        self.activePiramids = [];
      }
    }
  }))
  .actions(self => ({
    async getPiramidsByApiKey(params) {
      const res = await piramidService.getPiramidsByApiKey(params);
      return self.getPiramidsByApiKeySuccess(res);
    },
    getPiramidsByApiKeySuccess(res) {
      if (res) {
        self.piramidTotalPages = res.data.totalPages;
        self.piramids = res.data.list;
      } else {
        self.piramids = [];
      }
    }
  }))
  .actions(self => ({
    async getPiramidDetail(params) {
      const res = await piramidService.getPiramidDetail(params);
      return self.getPiramidDetailSuccess(res);
    },
    getPiramidDetailSuccess(res) {
      if (res) {
        self.piramidDetail = res.data;
        self.piramidPrice = Number(res.price).toFixed(4);
      } else {
        self.piramidDetail = null;
        self.piramidPrice = 0;
      }
    }
  }))
  .actions(self => ({
    async getAllPiramidSlots(params) {
      const res = await piramidService.getAllPiramidSlots(params);
      return self.getAllPiramidSlotsSuccess(res);
    },
    getAllPiramidSlotsSuccess(res) {
      if (res) {
        self.piramidSlots = res.data.list;
      } else {
        self.piramidSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getActivePiramidSlots(params) {
      const res = await piramidService.getActivePiramidSlots(params);
      return self.getActivePiramidSlotsSuccess(res);
    },
    getActivePiramidSlotsSuccess(res) {
      if (res) {
        self.piramidSlots = res.data.list;
      } else {
        self.piramidSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getRecentPiramidSlots(params) {
      const res = await piramidService.getRecentPiramidSlots(params);
      return self.getRecentPiramidSlotsSuccess(res);
    },
    getRecentPiramidSlotsSuccess(res) {
      if (res) {
        self.piramidRecentSlots = res.data.list;
      } else {
        self.piramidRecentSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getArchivePiramidSlots(params) {
      const res = await piramidService.getArchivePiramidSlots(params);
      return self.getArchivePiramidSlotsSuccess(res);
    },
    getArchivePiramidSlotsSuccess(res) {
      if (res) {
        self.piramidArchiveSlots = res.data.list;
      } else {
        self.piramidArchiveSlots = [];
      }
    }
  }))
  .actions(self => ({
    async getPiramidStatics(params) {
      
      const res = await piramidService.getPiramidStatics(params);
      return self.getPiramidStaticsSuccess(res);
    },
    getPiramidStaticsSuccess(res) {
      if (res) {
        self.piramidStatics = res.data;
      } else {
        self.piramidStatics = [];
      }
    }
  }))
  .actions(self => ({
    async getPiramidProfit(params) {
      
      const res = await piramidService.getPiramidProfit(params);
      return self.getPiramidProfitSuccess(res);
    },
    getPiramidProfitSuccess(res) {
      if (res) {
        self.piramidProfit = res.data;
      } else {
        self.piramidProfit = [];
      }
    }
  }))
  .actions(self => ({
    async startPiramid(params) {
      return await piramidService.startPiramid(params);
    },

    async pausePiramid(params) {
      return await piramidService.pausePiramid(params);
    },

    async deletePiramid(params) {
      return await piramidService.deletePiramid(params);
    },

    async addPiramid(params) {
      return await piramidService.addPiramid(params);
    },

    async updatePiramid(params) {
      return await piramidService.updatePiramid(params);
    },

    async somethingBig(params) {
      return await piramidService.somethingBig(params);
    },
    async sellPiramidSlot(params) {
      return await piramidService.sellPiramidSlot(params);
    }
  }));
