import {
  types,
  flow
} from 'mobx-state-tree';
import waveService from '../../services/WaveService';

import { MaType } from './MaTypes';
import { WaveType, WaveTriggerType } from './WaveTypes';

export const Wave = types
  .model('Wave', {
    ma: types.optional(types.array(MaType), []),

    activeWaves: types.optional(types.array(WaveType), []),
    waveTotalPages: types.optional(types.number, 0),
    waveDetail: types.maybe(WaveType),
    waveTriggers: types.optional(types.array(WaveTriggerType), []),
    waveTriggerTotalPages: types.optional(types.number, 0),
  })

  .actions(self => ({
    async getMa(params) {
      return await waveService.ma(params);
    },

    
  }))
  
  .actions(self => ({
    async getAllWaves(params) {
      const res = await waveService.getAllWaves(params);
      return self.getAllWavesSuccess(res);
    },
    getAllWavesSuccess(res) {
      if (res) {
        self.waveTotalPages = res.data.totalPages;
        self.waves = res.data.list;
      } else {
        self.waves = [];
      }
    }
  }))
  .actions(self => ({
    async getActiveWaves(params) {
      const res = await waveService.getActiveWaves(params);
      return self.getActiveWavesSuccess(res);
    },
    getActiveWavesSuccess(res) {
      if (res) {
        // self.waveTotalPages = res.data.totalPages;
        self.activeWaves = res.data.list;
      } else {
        self.activeWaves = [];
      }
    }
  }))
  .actions(self => ({
    async getWavesByApiKey(params) {
      const res = await waveService.getWavesByApiKey(params);
      return self.getWavesByApiKeySuccess(res);
    },
    getWavesByApiKeySuccess(res) {
      if (res) {
        self.waveTotalPages = res.data.totalPages;
        self.waves = res.data.list;
      } else {
        self.waves = [];
      }
    }
  }))
  .actions(self => ({
    async getWaveDetail(params) {
      const res = await waveService.getWaveDetail(params);
      return self.getWaveDetailSuccess(res);
    },
    getWaveDetailSuccess(res) {
      if (res) {
        self.waveDetail = res.data;
        self.wavePrice = Number(res.price).toFixed(4);
      } else {
        self.waveDetail = null;
        self.wavePrice = 0;
      }
    }
  }))
  .actions(self => ({
    async getAllWaveTriggers(params) {
      const res = await waveService.getAllWaveTriggers(params);
      return self.getAllWaveTriggersSuccess(res);
    },
    getAllWaveTriggersSuccess(res) {
      if (res) {
        self.waveTriggers = res.data.list;
      } else {
        self.waveTriggers = [];
      }
    }
  }))
  .actions(self => ({
    async getActiveWaveTriggers(params) {
      const res = await waveService.getActiveWaveTriggers(params);
      return self.getActiveWaveTriggersSuccess(res);
    },
    getActiveWaveTriggersSuccess(res) {
      if (res) {
        self.waveTriggers = res.data.list;
      } else {
        self.waveTriggers = [];
      }
    }
  })) 
  .actions(self => ({
    async startWave(params) {
      return await waveService.startWave(params);
    },

    async pauseWave(params) {
      return await waveService.pauseWave(params);
    },

    async deleteWave(params) {
      return await waveService.deleteWave(params);
    },

    async addWave(params) {
      return await waveService.addWave(params);
    },

    async updateWave(params) {
      return await waveService.updateWave(params);
    },
  }));
  
  
