import { types } from 'mobx-state-tree';

export const SnackType = types
  .model('SnackType', {
    message: types.maybeNull(types.string),
    open: types.maybeNull(types.boolean),
    vertical: types.maybeNull(types.string),
    horizontal: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  });

export const CurrencyPriceType = types
  .model('CurrencyPriceType', {
    symbol: types.maybeNull(types.string),
    price: types.maybeNull(types.string)
  });
